<template>
  <div>
    <!-- On affiche pas la bar en production-->
    <v-btn
      v-if="env !== 'production'"
      :class="`disabledCursor ${env === 'development' ? 'envDev' : 'envStaging'}`"
      block
      :color="env === 'development' ? 'red' : 'yellow'"
    >
      {{ $t(`env.${env}`) }} {{ company }}
    </v-btn>

    <v-app-bar
      id="app-bar"
      :class="getClassAppBar"
      absolute
      app
      color="transparent"
      flat
      height="75"
    >
      <!-- <div>
        <span class="red--text">wwww {{ value }}    </span>
        <span class="red--text">Mobile {{ $vuetify.breakpoint.smAndDown }}</span>
      </div> -->
      <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="$vuetify.breakpoint.smAndDown ? setDrawer(!theme.drawer) : displayDrawer(value)"
      >
        <v-icon v-if="value">
          mdi-view-quilt
        </v-icon>

        <v-icon v-else>
          mdi-dots-vertical
        </v-icon>
      </v-btn>

      <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
        v-text="tr"
      />

      <v-spacer />

      <div class="mx-3" />

      <component
        :is="currentCompany"
        ref="logout"
      />
    </v-app-bar>
  </div>
</template>

<script>
  // Components
  import LogoutMsal from '@/components/Logout/LogoutMsal'
  import LogoutOidc from '@/components/Logout/LogoutOidc'

  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import { EventBus } from '@/utils/event-bus'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      LogoutMsal,
      LogoutOidc,
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      env: process.env.NODE_ENV,
      company: process.env.VUE_APP_COMPANY,
    }),

    computed: {
      ...mapState(['theme']),
      tr () {
        if (this.$route.meta.translate) {
          return this.$t(`${this.$route.meta.translate}`)
        } else {
          return this.$t(`${this.$route.name}`)
        }
      },
      currentCompany () {
        if (process.env.VUE_APP_AUTHENTIFICATION === 'msal') return LogoutMsal
        return LogoutOidc
      },
      getClassAppBar () {
        if (this.env === 'production') return ''
        return 'mt-7'
      },
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      displayDrawer (value) {
        // expandOnHover
        this.$emit('input', !value)
        // Display bar
        EventBus.$emit('display-sidebar')
      },
    },
  }
</script>

<style scoped>
  .disabledCursor {
    cursor: not-allowed !important;
  }

  .envStaging {
    color: rgb(0, 0, 0) !important;
    background-color: #fbff00 !important;
  }

  .envDev {
    color: rgb(255, 255, 255) !important;
    background-color: #ff0404 !important;
  }
</style>
